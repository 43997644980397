/** Contact us **/
.address__single {
    padding: 35px;
    position: relative;
    text-align: center;
    border-radius: 7px;
    margin-bottom: 25px;
    background-color: var(--pureWhite);
    box-shadow: #959da533 0 8px 24px;
  }
  
  .address__single::before {
    left: 0;
    width: 0;
    bottom: 0;
    height: 5px;
    content: "";
    border-radius: 7px;
    position: absolute;
    transition: var(--transition);
    background-color: var(--mainColor);
  }
  
  .address__single::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    content: "";
    border-radius: 7px;
    position: absolute;
    background-color: #bbbbbe;
    transition: var(--transition);
  }
  
  .address__single .address__icon {
    z-index: 1;
  padding:10px;
    width: 80px;
    height: 80px;
    font-size: 42px;
    position: relative;
    border-radius: 50%;
    background:var(--mainColor) ;
    color: var(--mainColor);
    transition: var(--transition);
    margin-bottom: 22px;
    margin-inline: auto;
    text-align: center;
  }
  .add__details a{text-decoration:none ;}
  
  .address__single .address__icon::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    z-index: -1;
    opacity: .07;
    border-radius: 50%;
    position: absolute;
    transition: var(--transition);
    background-color: var(--mainColor);
  }
  
  .address__single .address__icon::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    z-index: -1;
    border-radius: 50%;
    position: absolute;
    transform: scale(0);
    transition: var(--transition);
    background-color: var(--mainColor);
  }
  
  .address__single .address__icon i {
    color: var(--pureWhite);
  }
  
  .address__single .address__content .address__title {
    font-size: 22px;
    margin-bottom: 12px;
  }
  
  .address__single .address__content .add__details p {
    margin-bottom: 2px;
    display: flex;
    flex-direction: column;
  }
  
  .address__single .address__content .add__details p:last-child {
    margin-bottom: 0;
  }
  
  .address__single:hover::before {
    width: 100%;
  }
  
  .address__single:hover::after {
    width: 0;
  }
  
  .address__single:hover .address__icon::after {
    transform: scale(1);
  }
  
  .address__single:hover .address__icon,
  .address__single:hover .address__icon i {
    color: var(--pureWhite);
  }
  
  iframe {
    width: 100%;
    height: 550px;
  }
  
  .contact-bg:before {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    content: "";
    display: block;
    z-index: 1;
  }
  
  .map-box iframe {
    border: 0;
    width: 100%;
    height: 550px;
  }
  
  .contact-form form .form-group {
    margin-bottom: 25px;
  }
  
  .map-box {
    overflow: hidden;
  }
  
  
  /** Comment Form **/
  
  .comment-form .comment-notes {
    margin-bottom: 25px;
  }
  
  .form-control {
    padding: 15px 15px;
    font-size: 15px;
    color: #848484;
    border: 1px solid #eeeeee;
    border-radius: 0;
    font-family: var(--headingFont);
    font-weight: 500;
  }
  
  input:focus,
  .form-control:focus {
    outline: none !important;
    border-color: var(--mainColor);
    box-shadow: none !important;
  }
  
  
  /** Blog detail **/
  .post-detail__thumb img {
    border-radius: 10px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) and (max-width: 500px){
    .post-detail__wrapper {
      padding-right: 0;
    }
    .address__single .address__icon {
      z-index: 1;
    padding:20px;
      width: 80px;
      height: 80px;
      font-size: 42px;
      position: relative;
      border-radius: 50%;
      background:var(--mainColor) ;
      color: var(--mainColor);
      transition: var(--transition);
      margin-bottom: 22px;
      margin-inline: auto;
      text-align: center;
    }
  }
  
  .post-detail__content-area .meta-container {
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 15px;
  }
  
  .post-detail__content-area .meta-container .meta-line {
    font-size: 13px;
    margin-bottom: 10px;
    font-weight: 500;
    display: inline-block;
    margin-right: 10px;
    color: #666;
  }
  
  .post-detail__content-area .meta-container .meta-line a {
    color: #666;
  }
  
  .post-detail__content-area .meta-container .meta-line:last-child {
    margin-right: 0;
  }
  
  .post-detail__content-area .meta-container .meta-line i {
    margin-right: 5px;
    font-size: 15px;
    color: var(--mainColor);
  }
  
  .post-detail__content .post-detail__content-area .post-detail__title {
    margin-bottom: 15px;
    font-size: 30px;
    line-height: 1.2;
  }
  
  .post-detail__text p {
    font-size: 16px;
  }
  
  .post-detail__meta-img img {
    width: 100%;
    border-radius: 10px;
  }
  
  @media (max-width: 767px) {
    .post-detail__text p {
      font-size: 14px;
    }
  }
  
  .post__detail-actions {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
    padding: 30px 0;
  }
  
  @media(max-width:575px) {
    .post__detail-actions {
      flex-direction: column;
      gap: 40px
    }
  }
  
  .post__detail-actions h4 {
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: var(--fw-bold);
  }
  
  .post__detail-actions>div:last-child {
    text-align: right
  }
  
  @media(max-width:575px) {
    .post__detail-actions>div:last-child {
      text-align: left
    }
  }
  
  .post__social-links ul {
    gap: 26px;
    display: flex;
  }
  
  
  .post__social-links ul li a {
    color: #b9b9b9
  }
  
  .post__social-links ul li a:hover {
    color: var(--mainColor)
  }
  
  .post__tag-list {
    display: flex;
    gap: 10px
  }
  
  .post__tag-list .tag {
    border: 2px solid #f4f4f4;
    background: 0 0;
    line-height: 26px;
  
  }
  
  .post__tag-list .tag:hover {
    color: var(--pureWhite);
    background: var(--blackish-color);
    border-color: var(--blackish-color)
  }
  
  .post-detail__writer {
    border: 1px solid #f3f3f3;
    padding: 30px;
    display: flex;
    gap: 35px;
    align-items: center;
    border-radius: 10px;
    border-bottom: 3px solid var(--mainColor);
    overflow: hidden;
  }
  
  .post-writer__img img {
    border-radius: 15px;
  }
  
  @media only screen and (min-width:576px) and (max-width:767px),
  (max-width:575px) {
    .post-detail__writer {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 20px 16px
    }
  }
  
  .post-writer__content span {
    font-size: 12px;
    color: #999;
    margin-bottom: 0;
    display: block
  }
  
  .post-writer__content .writer-name {
    font-size: 36px;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 15px
  }
  
  @media only screen and (min-width:1200px) and (max-width:1399px) {
    .post-writer__content .writer-name {
      font-size: 34px
    }
  }
  
  @media only screen and (min-width:768px) and (max-width:991px),
  only screen and (min-width:576px) and (max-width:767px),
  (max-width:575px) {
    .post-writer__content .writer-name {
      font-size: 28px
    }
  }
  
  @media(max-width:450px) {
    .post-writer__content .writer-name {
      font-size: 22px
    }
  }
  
  .post-writer__content p {
    font-size: 14px;
    margin-bottom: 0
  }
  
  .post-detail__content-area p {
    font-size: 16px;
    margin-bottom: 30px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .post-detail__content-area p {
      font-size: 15px;
    }
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
    .post-detail__content-area p br {
      display: none;
    }
  }
  
  .post-detail__text-list ul li {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  .post-detail__text-list ul li:last-child {
    margin-bottom: 0;
  }
  
  .post-detail__text-list ul li i {
    margin-right: 20px;
    font-size: 18px;
    color: var(--mainColor);
  }
  
  .post-detail__comment {
    border-bottom: 1px solid var(--borderPrimary);
  }
  
  .post-detail__comment ul li {
    margin-bottom: 10px;
    list-style: none;
  }
  
  .post-detail__comment ul li.children {
    margin-left: 100px;
    margin-bottom: 30px;
  }
  
  @media (max-width: 767px) {
    .post-detail__comment ul li.children {
      margin-left: 0px;
    }
  }
  
  .post-detail__comment-form {
    margin-bottom: 20px;
  }
  
  .post-detail__comment-form-title {
    font-size: 26px;
    font-weight: 700;
  }
  
  .post-detail__comment-form p {
    color: var(--text-2);
    font-size: 16px;
    margin-bottom: 60px;
  }
  
  .post-comment__input {
    position: relative;
  }
  
  .post-comment__input::after {
    position: absolute;
    font-size: 18px;
    content: "";
    position: absolute;
    right: 18px;
    top: 13px;
    font-family: "boxicons";
    font-weight: 400;
  }
  
  .post-comment__input.name_field::after {
    content: "\ec63";
  }
  
  .post-comment__input.email_field::after {
    content: "\eac1";
  }
  
  .post-comment__input.url_field::after {
    content: "\eaf8";
  }
  
  .post-comment__input input {
    width: 100%;
    height: 55px;
    border: 1px solid #eeeeee;
    box-shadow: unset;
    line-height: 55px;
    border-radius: 5px;
    color: var(--darkBlack);
    background-color: var(--pureWhite);
    font-size: var(--fontSize);
    font-weight: 500;
    padding: 0 20px 2px;
  }
  
  .post-comment__input input::-webkit-input-placeholder {
    color: #1A3D7D;
  }
  
  .post-comment__input input::-moz-placeholder {
    color: #1A3D7D;
  }
  
  .post-comment__input input:-ms-input-placeholder {
    color: #1A3D7D;
  }
  
  .post-comment__input input::-ms-input-placeholder {
    color: #1A3D7D;
  }
  
  .post-comment__input input::placeholder {
    color: #1A3D7D;
  }
  
  .post-comment__input input:focus,
  .post-comment__input .form-control:focus {
    outline: none !important;
    border-color: var(--mainColor);
    box-shadow: none !important;
  }
  
  .post-comment__input textarea {
    line-height: unset;
    width: 100%;
    border-radius: 5px;
    color: #212529;
  }
  
  @media screen and (max-width: 767px) {
    .post-detail__comment-form .post-comment__input input {
      height: 50px;
      font-size: 14px;
    }
  
    .nice-select {
      height: 50px;
      font-size: 14px;
    }
  
    .post-comment__input::after {
      font-size: 16px;
      top: 12px;
    }
  }
  
  
  .post-detail__comment-input textarea {
    height: 175px;
    resize: none;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .post-detail__comment-title {
    font-size: 26px;
    font-weight: var(--fw-bold);
    margin-bottom: 35px;
  }
  
  .post-detail__comment-box {
    margin-bottom: 70px;
    gap: 25px;
  }
  
  .post-detail__comment-avater img {
    width: 80px;
    height: 80px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
  }
  
  .post-detail__comment-name {
    margin-bottom: 15px;
  }
  
  .post-detail__comment-name h5 {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 8px;
    font-weight: 700;
  }
  
  .post-detail__comment-name span {
    color: #b4b4b4;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  
  @media (max-width: 767px) {
    .post-detail__comment-text {
      margin-left: 0;
      margin-top: 15px;
    }
  }
  
  .post-detail__comment-text p {
    font-size: 16px;
    color: var(--text-2);
    margin-bottom: 15px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .post-detail__comment-text p {
      font-size: 15px;
    }
  }
  
  @media (max-width: 767px) {
    .post-detail__comment-text p {
      font-size: 14px;
    }
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
    .post-detail__comment-text p br {
      display: none;
    }
  }
  
  .post-detail__comment-reply {
    margin-top: 10px;
  }
  
  .post-detail__comment-reply a {
    font-weight: 700;
    font-size: 14px;
    color: var(--mainColor);
    border: 2px solid #f3f3f3;
    height: 40px;
    display: inline-block;
    line-height: 36px;
    border-radius: 10px;
    padding: 0 20px;
    text-transform: uppercase;
  }
  
  .post-detail__comment-reply a:hover {
    color: var(--pureWhite);
    background: var(--mainColor);
    border-color: var(--mainColor);
  }
  
  .post-detail__comment-agree {
    padding-left: 5px;
  }
  
  .post-detail__comment-agree input {
    margin: 0;
    appearance: none;
    -moz-appearance: none;
    display: block;
    width: 14px;
    height: 14px;
    background: var(--pureWhite);
    border: 1px solid #b9bac1;
    outline: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    flex: 0 0 auto;
    -webkit-transform: translateY(-1px);
    -moz-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    -o-transform: translateY(-1px);
    transform: translateY(-1px);
  }
  
  .post-detail__comment-agree input:checked {
    position: relative;
    background-color: var(--theme-1);
    border-color: transparent;
  }
  
  .post-detail__comment-agree input:checked::after {
    box-sizing: border-box;
    content: "\f00c";
    position: absolute;
    font-family: var(--ff-fontawesome);
    font-size: 10px;
    color: var(--pureWhite);
    top: 46%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .post-detail__comment-agree input:hover {
    cursor: pointer;
  }
  
  .post-detail__comment-agree label {
    padding-left: 8px;
    color: var(--text-1);
    line-height: 1;
  }
  
  .post-detail__comment-agree label a {
    color: var(--common-black);
    font-weight: 600;
    padding-left: 4px;
  }
  
  .post-detail__comment-agree label a:hover {
    color: var(--theme-1);
  }
  
  .post-detail__comment-agree label:hover {
    cursor: pointer;
  }
  
  

.appointment-box .heading__title {
    margin-bottom: 30px;
}

.appointment-box .heading__title h3 {
    color: var(--secondaryColor);
    margin: 0 0 30px 0;
    font-size: 24px;
    line-height: 30px;
}

.appointment-box .heading__title p {
    margin-bottom: 0;
}

.appoint__item {
    position: relative;
}

.appoint__item::after {
    position: absolute;
    font-size: 18px;
    content: "";
    position: absolute;
    right: 18px;
    top: 13px;
    font-family: "boxicons";
    font-weight: 400;
}

.appoint__item.name_field::after {
    content: "\ec63";
}

.appoint__item.email_field::after {
    content: "\eac1";
}

.appoint__item.phn_field::after {
    content: "\eb90";
}

.appoint__item.date_field::after {
    content: "\ea15";
}

.appoint__item.time_field::after {
    content: "\ec45";
}

.appoint__item span.lable {
    font-size: 13px;
    font-weight: 600;
    display: inline-block;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 7px;
}

.appoint__item i {
    right: 16px;
    position: absolute;
    top: calc(24px + (53px / 3));
    font-size: 18px;
}

.appoint__item input {
    width: 100%;
    height: 55px;
    border: 1px solid #eee;
    box-shadow: unset;
    line-height: 55px;
    border-radius: 5px;
    color: var(--darkBlack);
    background-color: var(--pureWhite);
    font-size: var(--fontSize);
    font-weight: 500;
    padding: 0 20px 2px;
}

.appoint__item input::-webkit-input-placeholder {
    color: var(--secondaryColor);
}

.appoint__item input::-moz-placeholder {
    color: var(--secondaryColor);
}

.appoint__item input:-ms-input-placeholder {
    color: var(--secondaryColor);
}

.appoint__item input::-ms-input-placeholder {
    color: var(--secondaryColor);
}

.appoint__item input::placeholder {
    color: var(--secondaryColor);
}

.appoint__item input:focus,
.appoint__item .form-control:focus {
    outline: none !important;
    border-color: var(--mainColor);
    box-shadow: none !important;
}

.appoint__item textarea {
    line-height: unset;
    width: 100%;
    border-radius: 5px;
}

.appointment-img-box {
    border-radius: 3px;
    overflow: hidden;
}

.mailsendbtn {
    display: inline-block;
    position: relative;
}

.mailsendbtn .loading {
    display: none;
    position: absolute;
    right: -40px;
    top: 6px;
    width: 28px;
    height: 28px;
    box-shadow: 0 0 3px #999;
    text-align: center;
}

.error_msg {
    display: block;
    color: #ff4141;
}
/*----------------------------------------*/
/* 13 - Nice Select
/*----------------------------------------*/

.nice-select {
    height: 55px;
    border: 1px solid #eee;
    box-shadow: unset;
    line-height: 55px;
    border-radius: 5px;
    color: var(--darkBlack);
    background-color: var(--pureWhite);
    font-size: var(--fontSize);
    font-weight: 500;
    padding: 0 20px 2px;
    width: 100%;
    /* margin-bottom: 20px; */
}

.nice-select:after {
    border-color: var(--secondaryColor);
    right: 16px;
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
    border-color: var(--mainColor);
}

.nice-select .list {
    background-color: #FFF;
    padding: 28px 0;
    -webkit-box-shadow: 0 8px 80px rgba(40, 40, 40, 0.08);
    box-shadow: 0 8px 80px rgba(40, 40, 40, 0.08);
    border: 0;
    min-width: 200px;
}

.nice-select .option {
    padding: 0 30px 0 40px;
    text-transform: capitalize;
}

.nice-select .option:after {
    content: "\e905";
    font-family: "icomoon";
    font-weight: 400;
    vertical-align: middle;
    font-size: 12px;
    opacity: 0;
    margin-left: 0;
    visibility: hidden;
    -webkit-transition: all 0.8s cubic-bezier(0.38, 0.17, 0.09, 0.99);
    -o-transition: all 0.8s cubic-bezier(0.38, 0.17, 0.09, 0.99);
    transition: all 0.8s cubic-bezier(0.38, 0.17, 0.09, 0.99);
}

.nice-select .option:hover:after {
    opacity: 1;
    margin-left: 15px;
    visibility: visible;
    color: var(--mainColor);
}

.nice-select .option.selected,
.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
    color: var(--mainColor);
}

@media screen and (max-width: 767px) {
    .appointment-box .appoint__item input {
        height: 50px;
        font-size: 14px;
    }

    .nice-select {
        height: 50px;
        font-size: 14px;
    }

    .appointment-box .appoint__item i {
        right: 15px;
        top: calc(22px + (50px / 3));
    }

    .appoint__item::after {
        font-size: 16px;
        top: 12px;
    }
}

/*----------------------------------------*/
/* 03 - Button Style
/*----------------------------------------*/

.buttn {
    text-transform: capitalize;
    position: relative;
    z-index: 1;
    font-size: 15px;
    font-weight: 700;
    min-width: 170px;
    height: 60px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 0 15px;
    border: 0;
    border-radius: 3px;
    overflow: hidden;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

  .buttn__lg {
    min-width: 200px;
}

.buttn__xl {
    height: 70px;
    line-height: 70px;
    min-width: 220px;
}

.buttn__xhight {
    height: 70px;
    line-height: 68px;
}

.buttn__rounded {
    border-radius: 50px;
    background:var(--mainColor);
    color:#fff;
}

@media (min-width: 320px) and (max-width: 767px) {
    .buttn:not(.buttn__link) {
        font-size: 13px;
        min-width: 120px;
        height: 40px;
    }

    .buttn__icon {
        padding: 0 10px;
    }

    .buttn__xl {
        height: 50px;
        min-width: 150px;
    }
}
