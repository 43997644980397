/*----------------------------------------*/
/*  13 - Footer
/*----------------------------------------*/

.footer {
    background: var(---mainColor);
    color: #fff;
    font-size: 13px;
    padding-top: 80px;
}

.footer a {
    color: #fff;
}

/* ---------------------- */
.footer__area .footer__widget {
    margin-bottom: 30px;
    margin-top: 20px;
    font-size: 13px;
    position: relative;
    color: #b8c3cb;
}

.footer-social-links:before {
    content: "";
    position: absolute;
    width: 200%;
    height: 1px;
    left: -20%;
    top: 0;
    background-color: rgb(255 255 255 / 10%);
}

.footer__widget .widget__title {
    color: #fff;
    font-size: 22px;
    font-weight: var(--fw-sbold);
}

.footer .footer__link li {
    margin-bottom: 2px;
    padding: 10px 0 10px;
}

.footer .footer__link li a {
    color: #b8c3cb;
    position: relative;
    font-family: var(--bodyFont);
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0;
    font-weight: normal;
    -webkit-transition: .6s;
    transition: .6s;
    display: block;
}


.footer .footer__link li a i {
    margin-right: 3px;
    font-size: 10px;
}


.footer .footer__link li a:hover {
    color: #fff;
    padding-left: 10px;
}

.footer-text-area {
    border-top: 1px solid rgb(255 255 255 / 10%);
    padding: 30px 0;
}

.footer__about .footer__logo{
    max-width: 230px;
    height:200px;
}

.footer__about .footer__logo img{
    max-width: 100%;
    height:100%;
}
.footer__about p{
    margin-top: 15px;
    color: #b8c3cb;
    font-size: 14px;
}

.footer-social-links .social-links li a {
    display: inline-block;
    background-color: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 0 4px;
    text-align: center;
    color: var(--blackish-color);
    border-radius: 50%;
    font-size: 13px;
}

.footer-social-links .social-links li a:hover {
    background-color: var(--mainColor);
    color: #fff;
}

.footer-social-links .social-links {
    margin-top: -15px;
}

.footer__contacts {
    position: relative;
}

.footer__info-title {
    font-size: 14px;
    font-family: var(--headingFont);
    font-weight: var(--fw-sbold);
    color: var(--pureWhite);
    margin: 20px;
    
}

.footer__contacts .footer__info {
    display: flex;
    gap: 10px;
    place-items: center;
}

.footer__contacts .footer__info i {
    border:1px solid var(---secondaryColor);
    height: 30px;
    line-height: 30px;
    width: 30px;
    color: var(--pureWhite);
    display: flex;
    place-content: center;
    border-radius: 3px;
}

.footer__contacts .footer__info a {
    color: #b8c3cb;
    font-size: 13px;
}

.social__links {
    margin-top: 20px;
}

.social__links .social__icons li {
    display: inline-block;
    padding-right: 5px;

}

.social__links .social__icons li a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border:1px solid var(---secondaryColor);
    border-radius: 3px;
    transition: all .5s ease-in-out;
    display: inline-block;
    color: var(--pureWhite);
    font-size: 15px;
    -webkit-transition: 0.3s all;
    transition: 0.3s all;
}

.social__links .social__icons li a:hover {

    color: var(--pureWhite);
    background: var(--secondaryColor);

}

@media only screen and (max-width: 991px) {
    .footer{
        padding-top: 35px;
    }
    .footer-widget-area{
        margin-bottom: 40px;
    }
}