@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&family=Teko&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300&display=swap');
:root {
    ---mainColor:#3c8599;
    --title:  'Prata', serif;
    /* --parafont:'Teko', sans-serif; */
    --mainColor-50: hsl(195.44, 67.06%, 50%);
    --mainColor-variant: #1bbde4;
    ---secondaryColor: #ea88b0;
   
    --pureWhite: #ffffff;
    --color2: #4a4e56;
    --darkBlack: #000000;
   
   
    
    --responsive-breakpoint: 1200px;
    --parafont: 'Poppins', sans-serif;;
    --bodyFont-variant: 400;
    --fontSize-base: 14;
    --fontSize: 15px;
    --body-lh: 1.8;
    --bodyColor: #666;
    --headingFont: 'Quicksand', sans-serif;
    --heading-color: #081839;
    --headingFont-Variant: 700;
    --btn-Font: 'Quicksand', sans-serif;
    --btn-Font-variant: 700;
    --btn-Fontsize: 14px;
    --btn-lh: 1.5;
    --card-title-fontSize: 22px;
    --transition: .5s;
    --letter-spacing: -0.02em;
    --bdr-radius: 10px;
    --bdr-color: #ea88b0;
    /* --p-font: 'Public Sans', sans-serif; */
    --p-fontSize: 15px;
    --p-font-variant: 400;
    --p-color: #9B9B9B;
    --fonSize-h1: 40px;
    --fontSize-h2: 36px;
    --fontSize-h3: 24px;
    --fontSize-h4: 20px;
    --fontSize-h5: 16px;
    --fontSize-h6: 14px;
    --ltr-spacing-h1: -1px;
    --ltr-spacing-h2: -0.8px;
    --ltr-spacing-heading: -0.5px;
    --fw-normal: normal;
    --fw-elight: 200;
    --fw-light: 300;
    --fw-regular: 400;
    --fw-medium: 500;
    --fw-sbold: 600;
    --fw-bold: 700;
    --fw-ebold: 800;
    --fw-black: 900;
}
section
{
    padding:50px 0;

}

@media(max-width:500px)
{
    #firstSlide
    {
        background:url('../images/banner1.jpg');
        background-size:cover;
    }  
    #secondSlide
    { background:url('../images/banner2.jpg');
        background-size:cover;
       

    }
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    color:darkgoldenrod;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}
/* Panels */
.mySwiper
{
    
    height:80vh;
}
.banner
{width:100%;
    position:relative;
    height:80vh;
}
.banner img{
    width:100%;
    height:100%;
}
.tagline
{
    top:0;
    bottom:0;
    left:0; 
    right:0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
width:100%;
    position:absolute;
    z-index:10;
}
.tagline h2
{
    font-size:40px;
    font-weight:600;
    
    color:var(--pureWhite);
    font-family:var(--title);
}
.tagline p
{
    font-size:20px;
    color:var(--pureWhite);
    font-family:var(--bodyFont);
}
@media(max-width:500px)
{
    .mySwiper
{
    height:50vh;
}
section
{
    padding:20px 0;

}
.banner{
    height:50vh;

}
.banner img{
    height:50vh;
    
}
    .tagline
{
    padding:10px;
    top:0;
    bottom:0;
    left:0; 
    right:0;
    display:flex;
    justify-content: center;
    align-items: center;
width:100%;
    position:absolute;
    z-index:10;
}
    .tagline h2
{
    font-size:30px;
    color:var(--pureWhite);
    font-family:var(--title);
}
.tagline p
{
    font-size:20px;
    color:var(--pureWhite);
    /* font-family:var(--title); */
}
}
.swipe {
    position: relative;
    width: 100%;
    height: 100%; /* "min-height" doesn't work on Firefox. */
    background: #eee;
    overflow-x: hidden;
  }
  
  
  .panel {
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    padding: 20px;
  }
  
  .left {
    left: -100%;
  }
  
  .right {
    left: 100%;
  }
  
  /* Info */
  .info {
    position:relative;
    /* position: absolute; */
    width: 80%;
    max-width: 500px;
    bottom: 20%;
    right: 10%;
    pointer-events: none;
  }
  
  .inner {
    position: relative;
    padding:10px;
    /* background:linear-gradient(90deg, var(--mainColor) 0%, rgba(33, 51, 96, 0.5) 100%); */
color:#fff;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  
  .info:before {
    content: "";
    position: absolute;
    width: 65%;
    height: 80%;
    top: 0;
    left: 0;
    -webkit-transform: translate(-10px, -10px);
    -ms-transform: translate(-10px, -10px);
    -o-transform: translate(-10px, -10px);
    transform: translate(-10px, -10px);
    background: #E91E63;
    background: #3F51B5;
  }
  
  .buttons {
    /* position: absolute; */
    bottom: -50px;
    right: 5%;
    pointer-events: all;
  }
  
  .buttons button {
    transition: ease .4s;
  }
  
  .btn-prev,
  .btn-next {
    width: 60px;
    height: 60px;
    margin-left: 10px;
    border: none;
    outline: none;
    border-radius: 60px;
    color: #FFFFFF;
    background: -webkit-linear-gradient(top, #F5515F, #E91E63);
    background: linear-gradient(to bottom, #F5515F, #E91E63);
    box-shadow: 0px 3px 15px 2px rgba(245,81,95,.8);
    cursor: pointer;
  }
  
  .buttons button:hover {
    box-shadow: 0px 3px 30px 3px rgba(245,81,95,.8);
  }
  
  .buttons button:disabled {
    box-shadow: 0px 1px 5px 0px rgba(245,81,95,.8);
    background: -webkit-linear-gradient(top, #E91E63, #F5515F);
    background: linear-gradient(to bottom, #E91E63, #F5515F);
    cursor: default;
  }
  
  /* Optional content */
  .optional {
    width: 80%;
    max-width: 680px;
    margin: 6em auto;
    padding: 2em 3em;
    box-shadow: 
      0 3px 12px rgba(0,0,0,0.16), 
      0 3px 12px rgba(0,0,0,0.23);
  }
  
  @media (max-width: 620px) {
    body {
      font-size: 13px;
      line-height: 1.5em;
    }
    .info {
      position: absolute;
      width: 100%;
      max-width: 100%;
      bottom: 0;
      right: 0;
    }
  
    .inner {
      padding: .5em 1.5em;
      box-shadow: none;
    }
    
    .buttons {
      display: none;
    }
  }

/**
 * Button
 */
.btn-transparent {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
}
.btn-transparent:hover {
  background-color: #fff;
}

.btn-rounded {
  border-radius: 70px;
}

.btn-large {
  padding: 11px 45px;
  font-size: 18px;
}

/**
 * Change animation duration
 */
.animated {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
/* ------------------------------------------facility------------- */


/*----------------------------------------*/
/*  10 - Why Choose
/*----------------------------------------*/
.features-items {
    /* margin-top: -30px; */
    padding:20px 0;
    position: relative;
    z-index: 3;
    overflow: hidden;
}

.features .feature__card {
    padding: 35px;
    color: #FFF;
    position: relative;
    overflow: hidden;
    height: 100%;
    border-radius: 10px;
    background-color: var(--pureWhite);
    box-shadow: 0 0 20px 3px rgb(0 0 0 / 5%);
}

.features .feature__card:after {
    font-size: 160px;
    font-family: 'icomoon';
    opacity: 0.1;
    position: absolute;
    top: 10px;
    right: -75px;
    line-height: 1;
    -webkit-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    transform: rotate(35deg);
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.features .feature__card:hover:after {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

.features .feature__card.one::after {
    content: "\e932";
}

.features .feature__card.two::after {
    content: "\e900";
}

.features .feature__card.three::after {
    content: "\e94a";
}

.features .feature__card .feature__icon {
    font-size: 20px;
    line-height: 1;
    height:60px;
    width:60px;
    background: var(---mainColor);
    padding: 10px;
    display: flex;
    border-radius: 50%;
    border: 4px solid var(--pureWhite);
    display: flex;
    justify-content: center;
    align-items: center;
}

.features .feature__card:hover .feature__icon {
    -webkit-animation: icon-scale 2.5s infinite;
    animation: icon-scale 2.5s infinite;
}

@-webkit-keyframes icon-scale {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    25% {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    75% {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes icon-scale {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    25% {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    75% {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
.features{
    height:auto;
    padding:20px 0;
}
.features .feature__card .feature__content {
    margin-top: 15px;
}

.features .feature__card .feature__content h3 {
    text-transform: capitalize;
    font-size: 19px;
    line-height: 1.1;
    font-weight: var(--title);
    margin-bottom: 18px;
    color: var(---mainColor);
}
.features .feature__card .feature__content i {
border:1px solid var(---mainColor);
padding:5px;
border-radius: 50%;
    font-size:1rem;
    color:var(---mainColor);
    margin-right:10px;
}
.con_icon
{
    margin-top:10px;
}

.features .feature__card .feature__content p {
    color:#000;
    margin-bottom: 0;
    font-size: 14px;
    font-family:var(--parafont);
}

.features .feature__card .feature__content .buttn__white.buttn__outlined {
    height: 42px;
    border: 1px solid rgba(255, 255, 255, .25);
}

.features .feature__card .feature__content a.phone {
    font-size: 25px;
    line-height: 1;
    font-family: var(--bodyFont);
    font-weight: var(--fw-sbold);
    border: none;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 8px;
    color:var(---secondaryColor);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.features .feature__card .feature__content a.phone i {
    font-size: 16px;
    border-radius: 3px;
    border: 2px solid #FFF;
    text-align: center;
    padding: .5rem;
    
}

.features .feature__card .feature__content a.phone:hover {
    color: #13ADE5;
}

.features .feature__card .feature__content a.phone:hover i {
    color: #13ADE5;
    border-color: #13ADE5;
}

.features .feature__card .feature__content a.phone:after {
    display: none;
}

.features .feature__card .feature__content a.timetable:hover,
.features .feature__card .feature__content a.timetable:hover:after {
    color: #FFF;
    border-color: #FFF;
}



  /*** 
=============================================
    Team Area style
=============================================
***/
.team-area {
    padding-top: 80px;
    padding-bottom: 45px
}
.team-area .single-team-member {
    margin-bottom: 35px;
    display: block;
}
.team-area .single-team-member.hvr-underline-reveal:before {
    background: #45c4e9;
    height: 3px;
}
.team-area .single-team-member .img-holder {
    position: relative;
    display: block;
    overflow: hidden;
}
.team-area .single-team-member .img-holder img{
    transform: scale(1.1, 1.1);
    transition: all 0.5s ease 0s;
    width: 100%;
}
.team-area .single-team-member:hover .img-holder img{
    transform: scale(1, 1); 
}
.team-area .single-team-member:hover .img-holder .overlay-style-one {
    transform: translateY(0%); 
    opacity: 1;
}

.team-area .single-team-member .img-holder .overlay-style-one .box .content ul li{
    display: inline-block;
    margin: 0 3px;    
}
.team-area .single-team-member .img-holder .overlay-style-one .box .content ul li a i{
    background: rgba(255, 255, 255, 0.7);  
    height: 40px; 
    width: 40px; 
    display: block; 
    border-radius: 50%; 
    color: #172b42;  
    font-size: 13px;  
    line-height: 42px; 
    transition: all 500ms ease;
}
.team-area .single-team-member .img-holder .overlay-style-one .box .content ul li a:hover i{
    background: #a12a7e;
    color: #fff;
}

.team-area .single-team-member .text-holder {
    /* background: #fff; */
    padding-top: 22px;
    padding-bottom: 20px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}
.team-area .single-team-member .text-holder h3{
    color: #222222;
    font-size: 18px;
    font-weight: 600;
    text-transform: none;
    margin: 0 0 5px;
}
.team-area .single-team-member .text-holder span{
    color: #a12a7e;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}
.team-area .single-team-member .text-holder .text{
    padding-top: 12px;    
}
.team-area .single-team-member .text-holder .text p{
    margin: 0;
}
.single-service-item {
    
    overflow: hidden;
    display: block;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    border-radius:10px;
}
.single-service-item .img-holder {
    position: relative;
    /* width: 300px;     */
    height:300px;
}
/* .single-service-item .img-holder{} */
.single-service-item .img-holder img{
    width: 100%;
    height:100%;    
}
/* .single-service-item .img-holder .content {} */
.single-service-item .text-holder {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    background: #fff;
    /* padding: 0px 15px 0; */
    transform: translate3d(0px, -110%, 0px);   
    transition: all 500ms ease;
    transition-delay: 0.5s;
}
.single-service-item:hover .text-holder{
    transition: all 500ms ease;
    transform: translate3d(0px, 0%, 0px);    
}
.single-service-item .text-holder .inner {
    display: table;
    width: 100%;
    height: 100%;
}
.single-service-item .text-holder .inner .inner-content {
    display: table-cell;
    vertical-align: middle;
}
.single-service-item .text-holder .icon-box span:before {
    color: #a12a7e;
    font-size: 45px;
    line-height: 45px;
}
.single-service-item .text-holder h3 {
    color: #404040;
    font-size: 18px;
    font-weight: 600;
    margin: 13px 0 12px;
}
.single-service-item .text-holder p{
    margin: 0;
    font-family:var(--parafont);
}
.single-service-item .text-holder a {
    width: 150px;
    margin: 18px auto 0;
    padding: 9px 0 7px;
}

.single-service-item .text-holder-1 {
    /* padding-top: 22px;
    padding-bottom: 10px; */
    background: linear-gradient(90deg, var(--mainColor) 0%, rgba(33, 51, 96, 0.5) 100%);
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 15px;
    transform: translate3d(0px, 0%, 0px);
    transition: all 500ms ease;
    transition-delay: 0.5s;
}
.single-service-item .text-holder-1 .inner {
    display: table;
    width: 100%;
    height: 100%;
}
.single-service-item .text-holder-1 .inner .inner-content {
    display: table-cell;
    vertical-align: middle;
}
.single-service-item:hover .text-holder-1{
    transition: all 500ms ease;
    transform: translate3d(0px, 120%, 0px);
}
.single-service-item .text-holder-1 .icon-box span:before {
    color: #a12a7e;
    font-size: 45px;
    line-height: 45px;
}
.single-service-item .text-holder-1 h3 {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin: 13px 0 12px;
}
.service-area .owl-controls {
    position: absolute;
    right: 0;
    top: -90px;
}
.service-area .owl-theme .owl-nav{
    margin: 0;
}
.service-area .owl-theme .owl-nav [class*="owl-"] {
    background: transparent;
    border-radius: 0;
    color: #888888;
    font-size: 24px;
    font-weight: 700;
    height: 40px;
    line-height: 32px;
    margin: 0 0 0 0px;
    padding: 0;
    width: 40px;
    border: 2px solid #e1e1e1;    
    transition: all 700ms ease 0s;
}
.service-area .owl-theme .owl-nav .owl-next {
    margin-left: 10px;
}
.service-area .owl-theme .owl-nav [class*="owl-"]:hover{
    border-color: #a12a7e;
    color: #a12a7e;
}



/* --------------------about----------------------- */
.about-section
{
    background:#eee;
}
.heading-subheading {
    margin-bottom: 25px;
    /* text-align:justify; */
}

.heading-subheading.text-center {
    margin: 45px;
    
}

@media(max-width:500px)
{
    .heading-subheading.text-center {
        margin: 30px;
        
    } 
}

.heading-subheading.text-left {
    margin-bottom: 45px;
}

.heading-subheading .subtitle {
    text-align: left;
    z-index: 1;
    padding: 7px 25px;
    position: relative;
    border-radius: 30px;
    margin-bottom: 15px;
    display: inline-block;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    font-family:var(--title);
    font-weight: var(--fw-sbold);
    background-color:var(--bdr-color);
}

.heading-subheading .subtitle:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    content: "";
    box-shadow: 0 0 20px 3px rgb(0 0 0 / 5%);
    opacity: 0.07;
    position: absolute;
    border-radius: 30px;
    background-color:var(--bdr-color);
      
}

.heading-subheading .title {
    font-size: 30px;
    font-family:var(--title);
    line-height: 48px;
    letter-spacing: -1.2px;
    margin-bottom: 0;
    
}
.heading-subheading p {
    font-size: 1rem;
    font-family:var(--parafont);

    /* line-height: 48px; */
    /* letter-spacing: -1.2px; */
    margin-bottom: 0;
    
}
@media(max-width:500px)
{
    .heading-subheading .title {
        font-size: 25px;
        font-family:var(--title);
        line-height: 38px;
        letter-spacing: -1.2px;
        margin-bottom: 0;
        
    } 
    .heading-subheading p {
        font-size: .8rem;
        font-family:var(--parafont);
    text-align:justify ;
        /* line-height: 48px; */
        /* letter-spacing: -1.2px; */
        margin-bottom: 0;
        
    }
}
.heading__title {
    position: relative;
    margin-bottom: 23px;
    margin-top: -7px;
    text-align: left;
    
}

.heading__title .sub-title {
    z-index: 1;
    padding: 7px 25px;
    position: relative;
    border-radius: 30px;
    margin-bottom: 15px;
    display: inline-block;
    color: var(--mainColor);
    background-color: var(--pureWhite);
    font-size: 14px;
    font-weight: 600;
}

.heading__title .sub-title:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    content: "";
    opacity: 0.07;
    position: absolute;
    border-radius: 30px;
    background-color: var(--mainColor);
}

.heading__title h3 {
    text-transform: capitalize;
    font-size: 10px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 4px;
    display: inline-block;
    font-family: var(--bodyFont);
}

.heading__title h2.title {
    font-size: 48px;
    line-height: 54px;
    font-weight: 700;
    letter-spacing: -1.2px;
    text-transform: capitalize;
    font-family: var(--headingFont);
}

.heading__title h3 i {
    padding-right: 5px;
}

.heading__title.title-style-center_text {
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
}

.heading__title.title-style-center_text.heading__title h3:before {
    margin-left: -47px;
    left: 0;
    right: auto;
}

.heading__title.title-style-center_text.heading__title h3:after {
    margin-right: -47px;
    left: auto;
    right: 0;
}

.heading__title .featured-desc {
    padding-top: 5px;
}

.heading__title .header__title {
    padding-bottom: 0px;
}

.heading__title .desc__title {
    padding-top: 5px;
}

.heading__title.without-seperator {
    margin-bottom: 24px;
}

.heading__title.without-seperator:before {
    content: unset;
}

.heading__title.without-seperator .header__title {
    padding-left: 0;
}

.heading__title.without-desc__title {
    margin-bottom: 0px;
}

.heading__title.without-seperator h2.title {
    margin-bottom: 5px;
}




.about-bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 30px 0;
}

.image.about-signature {
    margin-right: 40px;
    width: 80px;
}

.about-name {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Syne', sans-serif;
}

.about-rol {
    color: #555;
    font-size: 16px;
    font-weight:600;
    color:var(---secondaryColor);
}

.about-img {
    position: relative;
    height:450px;
    /* padding:30px; */
    box-shadow: 0 0 20px 3px rgb(0 0 0 / 5%);
}


.about-img:before {
    content: '';
    position: absolute;
    top: -10px;
    right: -10px;
    left: 50%;
    bottom: 50%;
    z-index:-1;
    background:var(---mainColor);
    border-radius: 0 15px;
}

.about-img img {
    position: relative;
    z-index: 10;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.about-img img:hover {
    transform: scale(0.98);
}

.about-img-hotifer {
    position: absolute;
    left: -15px;
    bottom: -15px;
    width: 260px;
    padding: 26px;
    z-index: 11;
    border-radius: 2px;
    text-align: left;
    background:var(---mainColor);
    box-shadow: 0 5px 25px rgb(0 0 0 / 5%);
    border-radius: 10px;
}

.about-img-hotifer:before {
    font-family: 'boxicons';
    content: "\ec56";
    bottom: 20px;
    right: 20px;
    position: absolute;
    color: rgba(255, 255, 255, 0.3);
    font-size: 35px;
    font-weight: normal;
}

.about-img-hotifer p {
    color: #fff;
    font-size: 16px;
    margin-bottom: 0;
    text-align: left;
    font-weight: 400;
    font-family:var(--parafont);
}

@media (max-width: 480px) {
    .about-img-hotifer {
        width: 230px;
        padding: 20px;
    }

    .about-img-hotifer p {
        font-size: 14px;
        line-height: 1.5;
    }

    .about-img-hotifer:before {
        bottom: 1px;
        right: 15px;
        font-size: 30px;
    }
    .about-bottom {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0;
    }
}


/*----------------------------------------*/
/*  03 - Process
/*----------------------------------------*/
.process{
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.4),
        rgba(18, 20, 20, 0.4)
      ),url('../images/fert.jpg')

}
.process__item {
    position: relative;
    display: block;
    margin-bottom: 30px;
    transition: var(--transition);
}

.process__inner {
    position: relative;
    display: block;
}

.process__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border: 1px solid var(--mainColor);
    border-radius: 0.6rem;
    text-align: center;
    margin: 0 auto;
    background-color: var(---mainColor);
    overflow: hidden;
    transition: all 400ms linear;
    z-index: 1;
    top: -10px;
}

.process__item:hover .process__icon {
    border: 1px solid var(--mainColor);
}

.process__icon:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-radius: 0.6rem;
    background-color: var(--mainColor);
    transform: scale(0);
    transform-origin: center;
    transform-style: preserve-3d;
    transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
    z-index: -1;
}

.process__item:hover .process__icon:before {
    transform: scaleX(1);
}

.process__icon i {
    font-size: 30px;
    color: var(--pureWhite);
    position: relative;
    display: inline-block;
    transition: all 500ms linear;
    transition-delay: 0.1s;
    transform: scale(1);
}

.process__item:hover .process__icon i {
    transform: scale(0.9);
    color: var(--pureWhite);
}

.process__content {
    position: relative;
    display: block;
    text-align: center;
    /* background-color: var(--pureWhite); */
    padding: 60px 30px 39px;
    /* border: 2px; */
    margin-top: -60px;
    border-radius: 1rem;
    max-height:300px;
    min-height:100%;
    /* -webkit-box-shadow: 0 0 20px 3px rgb(0 0 0 / 5%); */
    /* box-shadow: 0 0 20px 3px rgb(0 0 0 / 5%); */
}

.process__step {
    font-size: 16px;
    font-weight: 700;
    line-height: 53px;
    color: var(--pureWhite);
}

.process__title {
    line-height: 26px;
}

.process__title a {
    font-size: 20px;
    /* font-weight: 500; */
    font-family: var(--title);
    color: var(--pureWhite);
    line-height: 26px;
    text-decoration: none;
    transition: all 500ms linear;
}

.process__item:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

/* .process__item:hover .process__title a {
    color: var(--secondaryColor);
} */

.process__text {
    font-size: 15px;
    font-weight: 400;
    /* color: var(--darkBlack); */
    opacity: .80;
    line-height: 24px;
    margin-top: 15px;
}

/*----------------------------------------*/
/*  02 - Single Features
/*----------------------------------------*/
.feature__list
{
    margin:0;
    padding:0;
}
.feature__list li{
    
    width:220px;
    
    background: var(--pureWhite);
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    font-family: var(--headingFont);
    font-size: 16px;
    /* font-weight: 600; */
    color: var(--pureWhite);
    display: flex;
    align-items: center;
    gap: 7px;
    text-transform: capitalize;
}
 .feature__list li i{
    color: var(--pureWhite);
}

.our-features .feature__list li{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);   
     background:var(--pureWhite);

    padding: 10px;
    height:80px;
    border-radius: 10px;
    margin-bottom: 15px;
    font-family: var(--parafont);
    font-size: 16px;
    /* font-weight: 600; */
    width:100%;
    color: var(---mainColor);
    display: flex;
    align-items: center;
    gap: 7px;
}
.our-features .feature__list li i{
    color: var(---mainColor);
    font-size:1.3rem;
}
.single__features .col-lg-6:nth-child(1) .featured__item {
    border-radius: 50px 0 0 0;
}

.single__features .col-lg-6:nth-child(1) .featured__item:hover {
    border-radius: 0;
}

.single__features .col-lg-6:nth-child(2) .featured__item {
    border-radius: 0 50px 0 0;
}

.single__features .col-lg-6:nth-child(2) .featured__item:hover {
    border-radius: 0;
}

.single__features .col-lg-6:nth-child(3) .featured__item {
    border-radius: 0 0 0 50px;
}

.single__features .col-lg-6:nth-child(3) .featured__item:hover {
    border-radius: 0;
}

.single__features .col-lg-6:nth-child(4) .featured__item {
    border-radius: 0 0 50px 0;
}

.single__features .col-lg-6:nth-child(4) .featured__item:hover {
    border-radius: 0;
}

.featured__item {
    text-align: center;
    -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    background-color: var(--pureWhite);
    padding: 20px;
    margin-bottom: 30px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    border-radius: var(--bdr-radius);
}

.featured__item i {
    display: inline-block;
    background-color: #def5ee;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    text-align: center;
    color: var(--secondaryColor);
    margin-bottom: 16px;
    font-size: 35px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.featured__item h3 {
    margin-bottom: 10px;
    font-size: 20px;
}

.featured__item p {
    margin-bottom: 15px;
    font-size: 14px;
}

.featured__item a {
    font-size: 14px;
}

.featured__item:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.featured__item:hover i {
    background-color: var(--secondaryColor);
    color: var(--pureWhite);
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
}

@media only screen and (max-width: 767px) {
    .single__features .col-lg-6:nth-child(1) .featured__item {
        border-radius: 0;
    }

    .single__features .col-lg-6:nth-child(2) .featured__item {
        border-radius: 0;
    }

    .single__features .col-lg-6:nth-child(3) .featured__item {
        border-radius: 0;
    }

    .single__features .col-lg-6:nth-child(4) .featured__item {
        border-radius: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single__features .col-lg-6:nth-child(1) .featured__item {
        border-radius: 0;
    }

    .single__features .col-lg-6:nth-child(2) .featured__item {
        border-radius: 0;
    }

    .single__features .col-lg-6:nth-child(3) .featured__item {
        border-radius: 0;
    }

    .single__features .col-lg-6:nth-child(4) .featured__item {
        border-radius: 0;
    }

}


/*----------------------------------------*/
/*  16 - List Gourp
/*----------------------------------------*/

.list-group-borderless .list-group-item {
    border: none;
    color: #8f9397;
    padding: 0.3rem 0;
    background: transparent;
    font-family: var(--headingFont);
    font-size: 18px;
    font-weight: 600;
    color: var(--blackish-color);
    display: flex;
    align-items: center;
}

.list-group-borderless .list-group-item i {
    margin-right: 10px;
    color: var(--mainColor);
}

.list-group.list-group-borderless .list-group-item:hover {
    color: var(--mainColor) !important;
}

/*----------------------------------------*/
/* 12 - Appointment
/*----------------------------------------*/

/*----------------------------------------*/
/* 13 - Nice Select
/*----------------------------------------*/

.nice-select {
    height: 55px;
    border: 1px solid #eee;
    box-shadow: unset;
    line-height: 55px;
    border-radius: 5px;
    color: var(--darkBlack);
    background-color: var(--pureWhite);
    font-size: var(--fontSize);
    font-weight: 500;
    padding: 0 20px 2px;
    width: 100%;
    /* margin-bottom: 20px; */
}

.nice-select:after {
    border-color: var(--secondaryColor);
    right: 16px;
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
    border-color: var(--mainColor);
}

.nice-select .list {
    background-color: #FFF;
    padding: 28px 0;
    -webkit-box-shadow: 0 8px 80px rgba(40, 40, 40, 0.08);
    box-shadow: 0 8px 80px rgba(40, 40, 40, 0.08);
    border: 0;
    min-width: 200px;
}

.nice-select .option {
    padding: 0 30px 0 40px;
    text-transform: capitalize;
}

.nice-select .option:after {
    content: "\e905";
    font-family: "icomoon";
    font-weight: 400;
    vertical-align: middle;
    font-size: 12px;
    opacity: 0;
    margin-left: 0;
    visibility: hidden;
    -webkit-transition: all 0.8s cubic-bezier(0.38, 0.17, 0.09, 0.99);
    -o-transition: all 0.8s cubic-bezier(0.38, 0.17, 0.09, 0.99);
    transition: all 0.8s cubic-bezier(0.38, 0.17, 0.09, 0.99);
}

.nice-select .option:hover:after {
    opacity: 1;
    margin-left: 15px;
    visibility: visible;
    color: var(--mainColor);
}

.nice-select .option.selected,
.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
    color: var(--mainColor);
}

@media screen and (max-width: 767px) {
    .appointment-box .appoint__item input {
        height: 50px;
        font-size: 14px;
    }
    .appointment-box
    {
        padding:10px;
    }
    .nice-select {
        height: 50px;
        font-size: 14px;
    }

    .appointment-box .appoint__item i {
        right: 15px;
        top: calc(22px + (50px / 3));
    }

    .appoint__item::after {
        font-size: 16px;
        top: 12px;
    }
}

.buttn__lg {
    min-width: 200px;
}

.buttn__xl {
    height: 70px;
    line-height: 70px;
    min-width: 220px;
}

.buttn__xhight {
    height: 70px;
    line-height: 68px;
}

.buttn__rounded {
    border-radius: 50px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .buttn:not(.buttn__link) {
        font-size: 13px;
        min-width: 120px;
        height: 40px;
    }

    .buttn__icon {
        padding: 0 10px;
    }

    .buttn__xl {
        height: 50px;
        min-width: 150px;
    }
}

.txt_fld-nx {
    border-left: 1px solid var(--blackish-color);
    padding: 0 0 0 20px;
}

.txt_fld-nx span {
    display: block;
}

.icon_fld-nx {
    text-align: center;
    font-size: 25px;
    margin: 0 20px 0 0;
    color: var(--pureWhite);
}

.v-center {
    align-self: center;
    align-items: center;
}

.appointment {
    background:url('/src/images/page_banner.jpg');
background-size: cover;
    position: relative;
    z-index: 2;
}

.appointment::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, var(--mainColor) 0%, rgba(33, 51, 96, 0.5) 100%);
    z-index: -1;
}

.appointment .heading-subheading .subtitle {
    background-color: var(--pureWhite);
    color: var(--secondaryColor);
}

.appointment p.desc {
    color: var(--pureWhite);
}

.appointment .connect_block a {
    border-radius: 8px;
    background: var(--secondaryColor);
    /* border: 1px solid var(---secondaryColor); */
    padding: 20px;
    display: flex;
    margin:10px 0;
    text-decoration: none;
}

.appointment .connect_block a span.small-text {
    font-size: 14px;
    color: var(--pureWhite);
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2.2px;
}

.appointment .connect_block a span.large-text {
    font-size: 20px;
    color: var(--pureWhite);
    line-height: 34px;
    font-weight: 700;
}

@media only screen and (max-width:520px) {

    .appointment .connect_block a span.small-text {
        font-size: 13px;
        letter-spacing: 0
    }

    .appointment .connect_block a span.large-text {
        font-size: 18px;
        line-height: 28px
    }

    .txt_fld-nx {
        padding: 0 0 0 15px
    }

    .icon_fld-nx {
        font-size: 22px;
        margin: 0 15px 0 0
    }

}

.appointment-box {
    padding: 50px 40px;
    background-color: #f8f8f8;
    border-radius: 20px;
    -webkit-box-shadow: 0 1px 73px 0 rgb(11 12 38 / 7%);
    box-shadow: 0 1px 73px 0 rgb(11 12 38 / 7%);
}
.appointment-box h3{
   font-family:var(--title);
}
@media(max-width:500px) {
    
    .appointment-box 
    {
        padding:10px;
    }
    
}
.appointment-box .heading__title {
    margin-bottom: 30px;
}

.appointment-box .heading__title h3 {
    color: var(--secondaryColor);
    margin: 0 0 30px 0;
    font-size: 24px;
    line-height: 30px;
}

.appointment-box .heading__title p {
    margin-bottom: 0;
}

.appoint__item {
    position: relative;
}

.appoint__item::after {
    position: absolute;
    font-size: 18px;
    content: "";
    position: absolute;
    right: 18px;
    top: 13px;
    font-family: "boxicons";
    font-weight: 400;
}

.appoint__item.name_field::after {
    content: "\ec63";
}

.appoint__item.email_field::after {
    content: "\eac1";
}

.appoint__item.phn_field::after {
    content: "\eb90";
}

.appoint__item.date_field::after {
    content: "\ea15";
}

.appoint__item.time_field::after {
    content: "\ec45";
}

.appoint__item span.lable {
    font-size: 13px;
    font-weight: 600;
    display: inline-block;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 7px;
}

.appoint__item i {
    right: 16px;
    position: absolute;
    top: calc(24px + (53px / 3));
    font-size: 18px;
}

.appoint__item input {
    width: 100%;
    height: 55px;
    border: 1px solid #eee;
    box-shadow: unset;
    line-height: 55px;
    border-radius: 5px;
    color: var(--darkBlack);
    background-color: var(--pureWhite);
    font-size: var(--fontSize);
    font-weight: 500;
    padding: 0 20px 2px;
}

.appoint__item input::-webkit-input-placeholder {
    color: var(--secondaryColor);
}

.appoint__item input::-moz-placeholder {
    color: var(--secondaryColor);
}

.appoint__item input:-ms-input-placeholder {
    color: var(--secondaryColor);
}

.appoint__item input::-ms-input-placeholder {
    color: var(--secondaryColor);
}

.appoint__item input::placeholder {
    color: var(--secondaryColor);
}

.appoint__item input:focus,
.appoint__item .form-control:focus {
    outline: none !important;
    border-color: var(--mainColor);
    box-shadow: none !important;
}

.appoint__item textarea {
    line-height: unset;
    width: 100%;
    border-radius: 5px;
}

.appointment-img-box {
    border-radius: 3px;
    overflow: hidden;
}

.mailsendbtn {
    display: inline-block;
    position: relative;
}

.mailsendbtn .loading {
    display: none;
    position: absolute;
    right: -40px;
    top: 6px;
    width: 28px;
    height: 28px;
    box-shadow: 0 0 3px #999;
    text-align: center;
}

.error_msg {
    display: block;
    color: #ff4141;
}
/*----------------------------------------*/
/*  07 - Fun Fact
/*----------------------------------------*/

.counter-one {
    position: relative;
    display: block;
    background-color: #fff;
    padding: 20px 0 0 0 ;
    z-index: 1;
}

.counter-one-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    mix-blend-mode: overlay;
    z-index: -1;
}

.fun__fact-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
}

.counter-one__single {
    position: relative;
    display: block;
    z-index: 1;
    margin-bottom: 30px;
}

.counter-one-inner {
    position: relative;
    display: block;
    border: 2px solid#f8f8f8;
    padding: 2rem;
    text-align: center;
    border-radius: 1rem;
    box-shadow: 0 0 20px 3px rgb(0 0 0 / 5%);
}

.counter-one__icon {
    position: relative;
    display: flex;
    place-content: center;
}

.counter-one__icon i {
    position: relative;
    display: flex;
    font-size: 30px;
    color:#fff; 
    -webkit-transition: all 500ms linear;
    transition: all 500ms linear;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
    height: 60px;
    width: 60px;
    background-color:var(--mainColor);
    place-content: center;
    align-items: center;
    border-radius: 50%;
}

.counter-one__single:hover .counter-one__icon i {
    color: var(--pureWhite);
    transform: scale(.9);
    background: var(--mainColor);
}

.counter-one-inner__count-box {
    position: relative;
    display: flex;
    align-items: center;
    place-content: center;
    margin-top: 20px;
}

.counter-one-inner__count-box h3 {
    font-size: 20px;
    font-family: var(--parafont);
    color: var(--secondaryColor);
    font-weight: 700;
    line-height: 54px !important;
}

.counter-one-inner__plus {
    font-size: 25px;
    color: var(--secondaryColor);
    font-weight: 500;
    line-height: 40px;
    /* position: relative;
    bottom: -14px; */
}

.counter-one-inner__text {
    font-weight: 500;
    color: var(---secondaryColor);
    font-size: 18px;
    line-height: 34px;
}

.counter-one-inner .plus-two {
    font-size: 20px;
    color: var(--secondaryColor);
    font-weight: 500;
    line-height: 54px !important;
    /* top: -1px; */
    left: -3px;
}

/*----------------------------------------*/
/*  05 - Services
/*----------------------------------------*/
.services-section
{
    background: #eee;
}
.services .icon

.service__card {
    position: relative;
    display: block;
}

.service__card .serv__img {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: var(--bdr-radius);
    height:200px;
}
.service__card .serv__img img {
  height:100%;
  width:100%;
}

.service__card .serv__img:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.80;
    background-color: var(--mainColor);
    z-index: 1;
    transform: scale(0.0);
    transform-origin: bottom;
    transform-style: preserve-3d;
    transition: all 500ms ease 100ms;
}

.service__card:hover .serv__img:before {
    transform: scale(1.0);
}

.service__card .serv__img .serv__icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    display:flex;
    justify-content: center;
    align-items: center;
    padding:10px;
    /* line-height: 70px; */
    text-align: center;
    color: #ffffff;
    font-size: 35px;
    background: var(---mainColor);
    transition: all 500ms ease;
    z-index: 2;
    border-radius: 5px;
}
.service__card .serv__img .serv__icon  i {
    color: var(--pureWhite);
    font-size:1.2rem;
}
.service__card:hover .serv__img .serv__icon  i {
    color: var(--pureWhite);
    font-size:1.2rem;
}

.service__card .serv__img .serv__icon:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--secondaryColor);
    z-index: 1;
    transform: scale(0.0);
    transform-origin: top;
    transform-style: preserve-3d;
    transition: all 500ms ease 100ms;
    z-index: -1;
    border-radius: 5px;
}

.service__card:hover .serv__img .serv__icon:before {
    transform: scale(1.0);
}

.service__card .serv__img img {
    width: 100%;
}

.service__card .service__text {
    position: relative;
    display: block;
    /* border: 1px solid #eee; */
    border-top: none;
    /* padding: 10px; */
    margin-top:10px;
    width: 92%;
    background: var(--pureWhite);
    border-radius: 8px;
    /* margin-top: -80px; */
    z-index: 1;
}

.service__card .service__text h3 {
    font-family: var(--title);
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 15px;
}

.service__card .service__text h3 a {
    color: var(--blackish-color);
    text-decoration: none;
}

.service__card .service__text h3 a:hover {
    color: var(--mainColor);
}

.service__card .service__text p {
    font-size: 15px;
    line-height: 26px;
    margin: 0;
}

@media (max-width: 767px) {
    .service__card .service__text {
        width: 100%;
        margin: 0;
        border-radius: 0 0 10px 10px;
        /* padding: 20px; */
    }

    .service__card .serv__img {
        border-radius: 10px 10px 0 0;
    }

    .service__card .service__text p {
        font-size: 14px;
        line-height: 24px;
    }
}
/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0 10px 60px;
  }
  
  .features .icon-box i {
    font-size: 48px;
    float: left;
    color: #3fbbc0;
  }
  
  .features .icon-box p {
    font-size: 15px;
    color: #848484;
    margin-left: 60px;
  }
  
  .features .image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
  }
  

  /*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .services .icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 50%;
    transition: 0.5s;
    color: #3fbbc0;
    overflow: hidden;
    padding:15px;
    box-shadow: 0px 0 25px rgba(0, 0, 0, 0.15);
  }
  
  .services .icon img {
    width:100%;
    height:100%;
    
  }
  
  .services .icon-box:hover .icon {
    box-shadow: 0px 0 25px rgba(63, 187, 192, 0.3);
  }
  
  .services .title {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 18px;
    position: relative;
    padding-bottom: 15px;
  }
  
  .services .title a {
    color: #444444;
    transition: 0.3s;
  }
  
  .services .title a:hover {
    color: #3fbbc0;
  }
  
  .services .title::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 2px;
    background: #3fbbc0;
    bottom: 0;
    left: calc(50% - 25px);
  }
  
  .services .description {
    line-height: 24px;
    font-size: 14px;
  }
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  
  .section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #3fbbc0;
    bottom: 0;
    left: calc(50% - 25px);
  }
  
  .section-title p {
    margin-bottom: 0;
  }



    
