:root {
    --mainColor: #3c8599;
}
/*----------------------------------------*/
/* 15 - Page Title
/*----------------------------------------*/
.testSwiper
{
    height:50vh;
}
.swiper-button-next:after,
 .swiper-button-prev:after
{color:var(---secondaryColor);
    font-size:30px;
}
.redBtn i
{
    color:var(--pureWhite);
    font-size:20px;
}
.breadcrumb__area {
    background:url('../images/page_banner.jpg');
    height:300px;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 2;

}

@media only screen and (min-width:576px) and (max-width:767px),
(max-width:575px) {
    .breadcrumb__area {
        padding: 80px 0;
        background-position: center center;
    }
}

.breadcrumb__area::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, var(--secondaryColor) 0%, rgba(33, 51, 96, 0.5) 100%);
    z-index: -1
}

.breadcrumb-menu li {
    display: inline-block;
    padding: 0 15px;
    position: relative
}

.breadcrumb-menu li span {
    color: var(--pureWhite);
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase
}

.breadcrumb-menu li a:hover span {
    color: var(--mainColor)
}

.breadcrumb-menu li:not(:last-child)::after {
    display: inline-block;
    color: var(--pureWhite);
    content: "\ed3b";
    position: absolute;
    right: -6px;
    top: 0;
    font-family: "boxicons"
}

.page-title {

    font-family:var(--title);
    color: var(--pureWhite);
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .page-title {
        font-size: 50px;
        margin-bottom: 20px
    }
}

@media only screen and (min-width:576px) and (max-width:767px),
(max-width:575px) {
    .page-title {
        font-size: 45px;
        margin-bottom: 10px
    }
}

@media(max-width:450px) {
    .page-title {
        font-size: 35px
    }
}

/*----------------------------------------*/
/*  18 - Call to action
/*----------------------------------------*/

.call-action {
    background:linear-gradient(90deg, var(--mainColor) 0%, rgba(33, 51, 96, 0.5) 100%),url('../images/fertility_banner.jpg');
    position: relative;
    z-index: 2;
    background-size: cover;
    
}
.call-action::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
   
    z-index: -1;
}

.action__phone{
    display: flex;
    align-items: center;
    place-content: center;
}

.action__phone span{
   color: var(--secondaryColor);
   margin-right: 10px;
   font-size: 18px;
   font-weight: 700;
}

.action__phone a.buttn__white{
    font-size: 20px;
    font-weight: 700;
    border-width: 1px solid #fff;

}
.buttn__white {
    padding:10px;
    background-color: var(--pureWhite);
    color: var(--secondaryColor);
}

.buttn__white:active,
.buttn__white:focus {
    color: var(--secondaryColor);
    border-color: var(--pureWhite);
    background-color: var(--pureWhite);
}

.buttn__white:hover {
    color: var(--pureWhite);
}

.buttn__white.buttn__outlined {
    color: var(--pureWhite);
    background-color: transparent;
    border: 2px solid var(--pureWhite);
}

.buttn__white.buttn__outlined:hover {
    color: var(--mainColor);
    background-color: var(--pureWhite);
}

.buttn__white-style2 {
    color: var(--mainColor);
}

.buttn__white-style2:before {
    background-color: var(--mainColor) !important;
}

.buttn__outlined {
    background-color: transparent;
}

.call-action .heading-subheading h4.subtitle {
    background-color: var(--pureWhite);
    color: var(--secondaryColor);
}
.heading-subheading .element-title{
    color: var(--darkBlack);
    font-family:var(--title);
}

@media (min-width: 320px) and (max-width: 767px) {
    .action__phone a.buttn__white{
        font-size: 13px;
        font-weight: 700;
        border-width: 1px;
    
    }
}
/*----------------------------------------*/
/*  02 - Single Features
/*----------------------------------------*/
 
.single__features .col-lg-6:nth-child(1) .featured__item {
    border-radius: 50px 0 0 0;
}

.single__features .col-lg-6:nth-child(1) .featured__item:hover {
    border-radius: 0;
}

.single__features .col-lg-6:nth-child(2) .featured__item {
    border-radius: 0 50px 0 0;
}

.single__features .col-lg-6:nth-child(2) .featured__item:hover {
    border-radius: 0;
}

.single__features .col-lg-6:nth-child(3) .featured__item {
    border-radius: 0 0 0 50px;
}

.single__features .col-lg-6:nth-child(3) .featured__item:hover {
    border-radius: 0;
}

.single__features .col-lg-6:nth-child(4) .featured__item {
    border-radius: 0 0 50px 0;
}

.single__features .col-lg-6:nth-child(4) .featured__item:hover {
    border-radius: 0;
}
.featured__img
{
    height:350px;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.featured__img img
{
    height:100%;
    width:100%;
}
.featured__item {
    text-align: center;
    -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    background-color: var(--pureWhite);
    padding: 20px;
    min-height:100%;
    
    margin: 30px 0;
    /* -webkit-transition: all 0.5s;
    transition: all 0.5s; */
    border-radius: var(--bdr-radius);
}
.featured__item li{
    list-style:none;
    
}

.featured__item i {
    display: inline-block;
    background-color:var(---mainColor);
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    text-align: center;
    color: var(--pureWhite);
    margin-bottom: 16px;
    font-size: 35px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.featured__item h3 {
    margin-bottom: 10px;
    font-size: 20px;
}

.featured__item p {
    margin-bottom: 15px;
    font-size: .9rem;
    font-family: var(--parafont);
    text-align:justify;
}

.featured__item a {
    font-size: 14px;
}

.featured__item:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.featured__item:hover i {
    background-color: var(--secondaryColor);
    color: var(--pureWhite);
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
}

@media only screen and (max-width: 540px) {
    .featured__item {
        text-align: center;
        -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
        background-color: var(--pureWhite);
        padding: 20px;
        height:auto;
        
        margin: 30px 0;
        /* -webkit-transition: all 0.5s;
        transition: all 0.5s; */
        border-radius: var(--bdr-radius);
    }
    .featured__item p {
        margin-bottom: 15px;
        font-size: .8rem;
        font-family: var(--parafont);
        text-align:justify;
    }
    .single__features .col-lg-6:nth-child(1) .featured__item {
        border-radius: 0;
    }

    .single__features .col-lg-6:nth-child(2) .featured__item {
        border-radius: 0;
    }

    .single__features .col-lg-6:nth-child(3) .featured__item {
        border-radius: 0;
    }

    .single__features .col-lg-6:nth-child(4) .featured__item {
        border-radius: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single__features .col-lg-6:nth-child(1) .featured__item {
        border-radius: 0;
    }

    .single__features .col-lg-6:nth-child(2) .featured__item {
        border-radius: 0;
    }

    .single__features .col-lg-6:nth-child(3) .featured__item {
        border-radius: 0;
    }

    .single__features .col-lg-6:nth-child(4) .featured__item {
        border-radius: 0;
    }

}
/*----------------------------------------*/
/*  11 - Testimonial
/*----------------------------------------*/
.testimonial-section
{
    background:#eee;
    height:auto;
    padding:50px 0;
}
.testi-item__single {
    position: relative;
    display: block;
    /* border: 1px solid var(--bdr-color); */
    height:250px;
    min-height:100%;
    overflow-y:scroll;
    border-radius: 20px;
    padding: 30px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    background: var(--pureWhite);
}

.testi-item__single:hover {
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
    transform: translateY(-10px);
}

.testi-item__client-box {
    background-color: var(--pureWhite);
    display: flex;
    align-items: center;
    gap: 1rem;
}

.testi-item__client-img {
    position: relative;
    display: block;
    width: 90px;
    height: 90px;
    padding: 4px;
    background: var(---mainColor);
    border-radius: 50%;
    background-image: -moz-linear-gradient(135deg, #3c8599, #213360);
    background-image: -webkit-linear-gradient(135deg, #3c8599, #213360);
    background-image: -ms-linear-gradient(135deg, #3c8599, #213360);
}

.testi-item__client-img img {
    width: 100% !important;
    border-radius: 50%;
    border: 4px solid var(--pureWhite);
}

.testi-item__client {
    position: relative;
    display: block;
}

.testi-item__client-name {
    font-size: 18px;
    color: var(--darkBlack);
    font-weight: var(--fw-sbold);
    line-height: 22px;
    letter-spacing: var(--letter-spacing);
}

.testi-item__client-sub-title {
    font-size: 14px;
    color: var(--color2);
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 0;
}

.testi-item__quote {
    display: flex;
    place-content: center;
}

.testi-item__quote i {
    font-size: 34px;
    color: var(--mainColor-variant);
    opacity: 0.5;
}

.ratings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.testi-item__star {
    margin-bottom: 0px;
}

.testi-item__star ul {
    margin: 0;
    padding: 0;
}

.testi-item__star ul li {
    display: inline-flex;
    align-items: center;
}

.testi-item__star ul li i {
    font-size: 15px;
    color: #fab600;
}

.testi-item__star ul li span {
    margin: 0px 5px;
    color: var(--darkBlack);
    font-size: 14px;
    font-weight: 600;
}

.testi-item__text {
    font-size:.8rem;
    /* font-weight: 400; */
    line-height: 1.8;
    letter-spacing: var(--parafont);
    margin-top: 15px;
}

/*----------------------------------------*/
/*  12 - Header
/*----------------------------------------*/

.header-style-1 .top__bar {
    background-color: #fff;
    position: relative;
    z-index: 2;
}

.header-style-1 .header__main {
    min-height: 120px;
    position: relative;
}

.header-style-1 .sticky-header .site-navigation ul.navigation>li>a,
.header-style-1 .header__main.sticky-header {
    min-height: 90px;
    line-height: 90px;
}

.top__bar .contact-info li {
    color: var(--pureWhite);
}

.top__bar .contact-info i {
    font-size: 14px; 
    color: var(--pureWhite);   
}

.top__bar ul.nav.link {
    margin-right: 10px;
}



/*----------------------------------------*/
/*  14 - Overlap Colomn
/*----------------------------------------*/

.overlap-colomn {
    position: relative;
}

.overlap-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9;
}

.overlap-img,
.overlap-bg {
    position: absolute;
    width: 100%;
    height: 100%;
}

.overlap-left {
    margin-left: -500px;
}

.overlap-right {
    margin-right: -500px;
    width: auto;
    left: 0;
    right: 0;
}

.overflow-hidden {
    overflow: hidden;
}

.content-element-text {
    position: relative;
    z-index: 99;
    padding-top: 60px;
    padding-bottom: 40px;
    padding-right: 30px;
}
#contact {
	background:#F9F9F9;
	padding:25px;
	margin:50px 0;
}

#contact h3 {
	color: #000;
	display: block;
	font-size: 30px;
	font-weight: 400;
}

#contact h4 {
	margin:5px 0 15px;
	display:block;
	font-size:13px;
}

fieldset {
	border: medium none !important;
	margin: 0 0 10px;
	min-width: 100%;
	padding: 0;
	width: 100%;
}

#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea {
	width:100%;
	border:1px solid #CCC;
	background:#FFF;
	margin:0 0 5px;
	padding:10px;
}

#contact input[type="text"]:hover, #contact input[type="email"]:hover, #contact input[type="tel"]:hover, #contact input[type="url"]:hover, #contact textarea:hover {
	-webkit-transition:border-color 0.3s ease-in-out;
	-moz-transition:border-color 0.3s ease-in-out;
	transition:border-color 0.3s ease-in-out;
	border:1px solid #AAA;
}

#contact textarea {
	height:100px;
	max-width:100%;
  resize:none;
}

#contact button[type="submit"] {
	cursor:pointer;
	width:100%;
	border:none;
	background:var(---mainColor);
	color:#FFF;
	margin:0 0 5px;
	padding:10px;
	font-size:15px;
}

#contact button[type="submit"]:hover {
	background:#09C;
	-webkit-transition:background 0.3s ease-in-out;
	-moz-transition:background 0.3s ease-in-out;
	transition:background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active { box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.5); }

#contact input:focus, #contact textarea:focus {
	outline:0;
	border:1px solid #999;
}
::-webkit-input-placeholder {
 color:#888;
}
:-moz-placeholder {
 color:#888;
}
::-moz-placeholder {
 color:#888;
}
:-ms-input-placeholder {
 color:#888;
}
