.App {
  font-family: sans-serif;
  background: #fff;
  color: white;
  /* height: 100vh; */
  width:300px;
  min-width:100%;
  box-sizing: border-box;
  padding:10px;
}


.tabStyle
{
  background:var(---secondaryColor);
}
.react-tabs {
 
  padding:30px;
  /* margin-left: 36px; */
  width: 100%;
  height: auto;
  /* border: 1px solid #3c3e43; */
  color: #000;
  background: #eee;
  /* text-align: left; */
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 0;
  padding: 0;
  color: white;
  background:#eee;
}
.react-tabs__tab {
  height: 70px;
  list-style: none;
  padding: 12px 6px;
  cursor: pointer;
  color: #000;
  width:100%;
  /* background:pink; */
  border-radius:5px;
  border:1px solid pink;
  /* border: 1px solid #000; */
}

.react-tabs__tab--selected {
  background: pink;
 
  color: black;
}

.react-tabs__tab-panel {
  display: none;
  background:#eee;
  width: 100%;
  padding:10px;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  padding-left: 24px;
  
}

.react-tabs__tab--selected {
  padding-left: 21px;
}

.panel-content {
  text-align: center;
}
@media (max-width:1200px)
{
  .App {
    font-family: sans-serif;
    background: #fff;
    color: white;
    /* height: 100vh; */
    box-sizing: border-box;
    display:flex;
    padding:0;
  }
  .react-tabs {
    
    padding:10px;
    
    height: auto;
    width:100%;
    color: #000;
    background: #eee;
    text-align: left;
  }
  
  .react-tabs__tab-list {
    display: flex;
    flex-direction: inherit;
    flex-wrap:wrap;
    width: 100%;
    min-width:100%;
    margin: 0;
    padding: 0;
    color: white;
    background:#eee;
  }

  .react-tabs__tab {
    height:auto;
    min-height:100%;
    list-style: none;
    padding: 12px 6px;
    cursor: pointer;
    color: #000;
    width:50%;
    word-break: break-all;
    /* background:pink; */
    border-radius:5px;
    border:1px solid pink;
    /* border: 1px solid #000; */
  }
}
@media (max-width:999px)
{
  .App {
    font-family: sans-serif;
    background: #fff;
    color: white;
    /* height: 100vh; */
    box-sizing: border-box;
    display:flex;
    padding:0;
  }
  .react-tabs {
    
    padding:10px;
    
    height: auto;
    width:100%;
    color: #000;
    background: #eee;
    text-align: left;
  }
  
  .react-tabs__tab-list {
    display: flex;
    flex-direction: inherit;
    flex-wrap:wrap;
    width: 100%;
    min-width:100%;
    margin: 0;
    padding: 0;
    color: white;
    background:#eee;
  }

  .react-tabs__tab {
    height:auto;
    min-height:100%;
    list-style: none;
    padding: 12px 6px;
    cursor: pointer;
    color: #000;
    width:25%;
    word-break: break-all;
    /* background:pink; */
    border-radius:5px;
    border:1px solid pink;
    /* border: 1px solid #000; */
  }
}
@media (max-width:750px)
{
  .App {
    font-family: sans-serif;
    background: #fff;
    color: white;
    /* height: 100vh; */
    box-sizing: border-box;
    display:flex;
    padding:0;
  }
  .react-tabs {
    
    padding:10px;
    
    height: auto;
    width:100%;
    color: #000;
    background: #eee;
    text-align: left;
  }
  
  .react-tabs__tab-list {
    display: flex;
    flex-direction: inherit;
    flex-wrap:wrap;
    width: 100%;
    min-width:100%;
    margin: 0;
    padding: 0;
    color: white;
    background:#eee;
  }

  .react-tabs__tab {
    height:70px;
    min-height:100%;
    list-style: none;
    padding: 12px 6px;
    cursor: pointer;
    color: #000;
    width:25%;
    word-break: break-all;
    /* background:pink; */
    border-radius:5px;
    border:1px solid pink;
    /* border: 1px solid #000; */
  }
 
}


@media (max-width:500px)
{
  .App {
    font-family: sans-serif;
    background: #fff;
    color: white;
    /* height: 100vh; */
    box-sizing: border-box;
    display:flex;
    padding:0;
  }
  .react-tabs {
    
    padding:10px;
    
    height: auto;
    width:100%;
    color: #000;
    background: #eee;
    text-align: left;
  }
  
  .react-tabs__tab-list {
    display: flex;
    flex-direction: inherit;
    flex-wrap:wrap;
    width: 100%;
    min-width:100%;
    margin: 0;
    padding: 0;
    color: white;
    background:#eee;
  }

  .react-tabs__tab {
    height:60px;
    min-height:100%;
    list-style: none;
    padding: 12px 13px;
    cursor: pointer;
    color: #000;
    width:50%;

    word-break: break-all;
    /* background:pink; */
    border-radius:5px;
    border:1px solid pink;
    /* border: 1px solid #000; */
  }
 
}
@media (max-width:300px)
{
  .App {
    font-family: sans-serif;
    background: #fff;
    color: white;
    /* height: 100vh; */
    box-sizing: border-box;
    display:flex;
    padding:0;
  }
  .react-tabs {
    
    padding:10px;
    
    height: auto;
    width:100%;
    color: #000;
    background: #eee;
    text-align: left;
  }
  
  .react-tabs__tab-list {
    display: flex;
    flex-direction: inherit;
    flex-wrap: wrap;
    width: 100%;
    min-width:100%;
    margin: 0;
    padding: 0;
    color: white;
    background:#eee;
  }

  .react-tabs__tab {
    height:auto;
    min-height:100%;
    list-style: none;
    padding: 12px 6px;
    cursor: pointer;
    color: #000;
    width:20px;
    word-break: break-all;
    /* background:pink; */
    border-radius:5px;
    border:1px solid pink;
    /* border: 1px solid #000; */
  }
 
}
@media (max-width:200px)
{
  .App {
    font-family: sans-serif;
    background: #fff;
    color: white;
    width:100%;
    /* height: 100vh; */
    box-sizing: border-box;
    display:flex;
    padding:0;
  }
  .react-tabs {
    
    padding:10px;
    
    height: auto;
    width:100%;
    color: #000;
    background: #eee;
    text-align: left;
  }
  
  .react-tabs__tab-list {
    display: flex;
    flex-direction: inherit;
    flex-wrap: wrap;
    width: 100%;
    min-width:100%;
    margin: 0;
    padding: 0;
    color: white;
    background:#eee;
  }

  .react-tabs__tab {
    height:auto;
    min-height:100%;
    list-style: none;
    padding: 12px 6px;
    cursor: pointer;
    color: #000;
    width:100%;
    word-break: break-all;
    /* background:pink; */
    border-radius:5px;
    border:1px solid pink;
    /* border: 1px solid #000; */
  }
 
}
.serImg
{
  /* width:300px; */
  height:300px;
}
.serImg img
{
  width:100%;
  height:100%;
}
.servDesc
{
  padding:20px;
  /* border: 1px solid #3c3e43; */
  color: #000;
  background: #eee;
  text-align: left;
  
}
.servDesc h2
{
  
  /* border: 1px solid #3c3e43; */
  color: #000;
  font-family:var(--title);
  
  text-align: left;
  
}
.servDesc p
{
  
  /* border: 1px solid #3c3e43; */
  color: #000;
  font-family:var(--parafont);
 
  text-align: left;
  
}
@media(max-width:500px)
{
  .servDesc p
  {
    
    /* border: 1px solid #3c3e43; */
    color: #000;
    font-family:var(--parafont);
   
    text-align: justify;
    
  }
}