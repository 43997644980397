/* :root {
    --primary: #13C5DD;
    --secondary: #354F8E;
    --light: #EFF5F9;
    --dark: #1D2A4D;
} */
/* =============================================
    Top bar area style
=============================================
***/ 
.top-bar-area {
    position: relative;
    line-height: 20px;
    z-index: 999;
    background:var(---mainColor);
    padding: 9px 0 8px;
}
.top-contact-info {
    overflow: hidden;
    margin: 6px 0;
}
.top-contact-info li {
    display: inline-block;
    /* float: left; */
    padding-right: 15px;
    color: #888888;
    line-height: 18px;
    border-right: 1px solid var(--pureWhite);
}
.top-contact-info li:last-child {
    margin: 0;
    padding: 0;
    border-right: none;
    border-left: 1px solid var(--pureWhite);
    padding-left: 15px;
}
.top-contact-info li i:before {
    color: #df74c0;
    font-size: 18px;
    line-height: 18px;
    display: inline-block;
    padding-right: 8px;
}
.top-bar-area .top-right {
    float: right;
}
.top-bar-area .top-right .social-links {
    overflow: hidden;
    float: left;
    padding-left: 20px;
    padding-right: 30px;
    margin: 8px 0;
}
.top-bar-area .top-right .social-links li {
    display: inline-block;
    /* float: left; */
    margin-right: 20px;
    line-height: 16px;
}
.top-bar-area .top-right .social-links li:last-child {
    margin: 0px;
}
.top-bar-area .top-right .social-links li a i {
    color:#fff;
    font-size: 14px;
    transition: all 500ms ease;
}
.top-bar-area .top-right .social-links li a:hover i{
    color: #fff;
}
/*==============================================
    Mainmenu Area Css
===============================================*/
.logo
{
    width:50px;
    height:50px;

}
.logo img
{
    width:100%;
    height:100%;
    transform:scale(1.4,1.5);
    
}
.navbar input[type="checkbox"],
.navbar .hamburger-lines{
    display: none;
}
.navbar{
    box-shadow: 0px 5px 10px 0px #aaa;
    /* position: fixed; */
    width: 100%;
    background: #fff;
    color: #000;
    opacity: 0.85;
    z-index: 100;
}

.navbar-container{
    display: flex;
    justify-content: space-between;
    height: 64px;
    align-items: center;
}

.menu-items{
    order: 2;
    display: flex;
    border-radius: 10px;;
}
/* .logo{
    order: 1;
    font-size: 2.3rem;
} */

.menu-items li{
    list-style: none;
    margin-left: 1.5rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: var(--title);
}

.navbar a{
    color:var(---mainColor);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease-in-out;
}

.navbar a:hover{
    color: #117964;
}

@media (max-width: 768px){
    .navbar{
        opacity: 0.95;
    }

    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines{
        display: block;
    }

    .navbar-container{
        display: block;
        position: relative;
        height: 64px;
    }

    .navbar-container input[type="checkbox"]{
        position: absolute;
        display: block;
        height: 32px;
        width: 30px;
        top: 20px;
        left: 20px;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
    }

    .navbar-container .hamburger-lines{
        display: block;
        height: 28px;
        width: 35px;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .navbar-container .hamburger-lines .line{
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #333;
    }
    
    .navbar-container .hamburger-lines .line1{
        transform-origin: 0% 0%;
        transition: transform 0.3s ease-in-out;
    }

    .navbar-container .hamburger-lines .line2{
        transition: transform 0.2s ease-in-out;
    }

    .navbar-container .hamburger-lines .line3{
        transform-origin: 0% 100%;
        transition: transform 0.3s ease-in-out;
    }

    .navbar .menu-items{
        padding-top: 80px;
        background: #fff;
        height: 70vh;
        width:400px;
        border-radius: 10px;
        max-width: 300px;
        transform: translate(-150%);
        display: flex;
        flex-direction: column;
        margin-left: -40px;
        padding-left: 40px;
        transition: transform 0.5s ease-in-out;
        box-shadow:  5px 0px 10px 0px #aaa;
        overflow: scroll;
    }

    .navbar .menu-items li{
        margin-bottom: 1.8rem;
        font-size: 1.1rem;
        font-weight: 500;
    }

    .logo{
        position: absolute;
        /* top: 10px; */
        right: 15px;
        font-size: 2.5rem;
    }

    .navbar-container input[type="checkbox"]:checked ~ .menu-items {
        transform: translateX(0);
        
    }
    
    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1{
        transform: rotate(45deg);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2{
        transform: scaleY(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3{
        transform: rotate(-45deg);
    }

}

@media (max-width: 500px){
    .navbar-container input[type="checkbox"]:checked ~ .logo{
        display: none;
    }
}



