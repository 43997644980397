.Specialty {
    font-family: "Roboto", Sans-serif;
    padding: 10px;
    border: 1px solid;
    border-color: #dddddd;
    text-align: justify;
}

.Specialty span {
    text-align: center;
    font-weight: 500;
    font-size: 1.1rem;
}

.Specialty .description {
    text-align: justify;
    font-weight: 100;
    font-size: 1rem;
}

.rowspace {
    padding-bottom: 1rem;
}
.docImg
{
    width:200px;
    height:200px;
    border:1px solid var(---mainColor);

}
.docImg img
{
    width:100%;
    height:100%;
    border:1px solid var(---mainColor);

}
.docName h2
{
   
   padding:10px 20px;
    font-size:20px;
    color:var(---secondaryColor);
    font-family: var(--title);
}

@media(max-width:500px)
{
    .docImg
{
 margin:0 30px;
    width:80%;
    height:200px;
    border:1px solid var(---mainColor);

}
.docImg img
{
    width:100%;
    height:100%;
    border:1px solid var(---mainColor);

}
}